import { User } from '~api/user';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from 'react-native-toast-message';
import { getCartProductCount } from '~store/cart/actions/cart';
import {
  getPregnancyTimePeriod,
  getAsyncData,
  getChildData,
  formatDate,
} from '~global/helpers';
import { Soco } from '~api/soco';
import { Platform } from 'react-native';
import { md5 } from 'pure-md5';
import { isNative } from '~global/screens';
import { endSession } from '~global/sociolla.dm.lib/sociolla-analytics';
import { GoogleTagManager, InsiderTracking } from '~global/sociolla.dm.lib';
const mandatoryUserInfo = [
  'user_name',
  'first_name',
  'last_name',
  'email',
  'phone_no',
  'gender',
];

// ...........Actions creator
export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATESTATUS = 'AUTHENTICATESTATUS';

export const SET_PROFILE = 'SET_PROFILE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_ADDRESS = 'SET_USER_ADDRESS';
export const SET_FRESH_LOGIN = 'SET_FRESH_LOGIN';
export const SET_TOUR_STEP = 'SET_TOUR_STEP';
export const SET_COMPLETED_PROFILE = 'SET_COMPLETED_PROFILE';
export const SET_FORGOT_PASSWORD = 'SET_FORGOT_PASSWORD';
export const SET_VOUCHER_COMPLETED_PROFILE = 'SET_VOUCHER_COMPLETED_PROFILE';
export const SET_QUICK_TOUR_HOME = 'SET_QUICK_TOUR_HOME';
export const SET_RESET_OTP = 'SET_RESET_OTP';
export const SET_RESET_OTPPARENT = 'SET_RESET_OTPPARENT';
export const SET_PARENT_EMAIL = 'SET_PARENT_EMAIL';
export const SET_IS_PARENT_VERIFICATION = 'SET_IS_PARENT_VERIFICATION';

// ...........Device storage
const saveDataToStorage = (token, userId, user) => {
  AsyncStorage.setItem(
    'userData',
    JSON.stringify({
      token,
      userId,
      user,
    }),
  );
};

const clearStorageData = (key) => {
  AsyncStorage.removeItem(key);
};

// ..........actions
export const authenticate = (payload) => {
  return { type: AUTHENTICATE, data: payload };
};

export const authenticateStatus = (payload) => {
  return { type: AUTHENTICATESTATUS, data: payload };
};

export const setTourStep = (payload) => {
  return { type: SET_TOUR_STEP, data: payload };
};

export const setCompletedProfile = (payload) => {
  return { type: SET_COMPLETED_PROFILE, data: payload };
};

export const setVoucherCompletedProfile = (payload) => {
  return { type: SET_VOUCHER_COMPLETED_PROFILE, data: payload };
};

export const setForgotPassword = (payload) => {
  return { type: SET_FORGOT_PASSWORD, data: payload };
};

export const setUserData = (payload) => {
  return { type: SET_USER_DATA, data: payload };
};

export const setUserAddress = (payload) => {
  return { type: SET_USER_ADDRESS, data: payload };
};

export const isCompleteUserInfo = (payload) => {
  return !mandatoryUserInfo.some((info) => !payload?.user?.[info]);
};

export const setFreshLogin = (payload) => {
  return { type: SET_FRESH_LOGIN, data: payload };
};

export const setQuickTourHome = (payload) => {
  return { type: SET_QUICK_TOUR_HOME, data: payload };
};

export const setResetOTP = (payload) => {
  return { type: SET_RESET_OTP, data: payload };
};

export const setResetOTPParent = (payload) => {
  return { type: SET_RESET_OTPPARENT, data: payload };
};

export const setParentEmail = (payload) => {
  return { type: SET_PARENT_EMAIL, data: payload };
};

export const setParentVerification = (payload) => {
  return { type: SET_IS_PARENT_VERIFICATION, data: payload };
};

const syncLillaProfiling = async (user) => {
  // if (user.gender !== 'female') return false

  const payload = await getAsyncData('@storage_profiling');
  if (!payload) {
    return false;
  }

  const res = await User.updateUserRequest(user.id, payload);
  return res.status == 200;
};

// common method to set mobile token for lilla
function getMobileTokenLilla(data) {
  const mobileTokenLilla = {
    android: null,
    ios: null,
  };
  if (data?.mobile_token_lilla?.length) {
    data.mobile_token_lilla.map((mobile) => {
      mobileTokenLilla[mobile?.device?.toLowerCase()] = mobile;
    });
  }
  return mobileTokenLilla;
}

export const userLogin = (payload) => {
  return async (dispatch) => {
    try {
      let res = {};
      if (isNative) {
        res = await User.loginRequest(payload);
      } else {
        res = await User.loginRequestWeb(payload);
      }
      const data = res.data.data;

      const mobileTokenLilla = {
        android: null,
        ios: null,
      };

      if (data?.user && !isNative) {
        const params = data.user;
        const userId = params?.my_sql_id || '';
        const userIdMongo = params?._id || '';
        const userEmail = params?.email || '';
        const hashedEmail = params?.email ? md5(params.email) : '';
        const userName = params?.user_name || '';
        const userFirstname = params?.first_name || '';
        const userLastname = params?.last_name || '';
        const userBirth = params?.date_of_birth || '';
        const userGender = params?.gender || '';
        const userPhone = params?.phone_no || '';
        const userCountry = params.country?.name || '';

        window.dataLayer.push({
          event: 'Login',
          userId: userId,
          userIdMongo: userIdMongo,
          userData: {
            userId,
            userIdMongo,
            userEmail,
            hashedEmail,
            userName,
            userFirstname,
            userLastname,
            userBirth,
            userGender,
            userPhone,
            userCountry,
          },
        });
      }
      if (data?.mobile_token_lilla?.length) {
        data.mobile_token_lilla.map((mobile) => {
          if (mobile.device === 'ios') {
            mobileTokenLilla.ios = mobile;
          }
          if (mobile.device === 'android') {
            mobileTokenLilla.android = mobile;
          }
        });
      }
      data.user.isCompleteProfile = isCompleteUserInfo({ user: data.user });
      dispatch(setFreshLogin(true));
      dispatch(
        authenticate({
          token: data.token,
          userId: data.user.id,
          user: data.user,
        }),
      );
      dispatch({ type: 'SET_MOBILE_TOKEN_LILLA', data: mobileTokenLilla });
      saveDataToStorage(data.token, data.user.id, data.user);
      await syncLillaProfiling(data.user).then((sync) => {
        if (sync) {
          AsyncStorage.removeItem('@storage_profiling');
        }
      });
      const userDetail = await dispatch(getUserDetail());
      dispatch(getCartProductCount());
      const asyncData = JSON.parse(await AsyncStorage.getItem('fcmToken'));
      if (asyncData && isNative) {
        Soco.saveFirebaseToken({
          token: asyncData.fcmToken,
          device: Platform.OS === 'android' ? 'lilla-android' : 'lilla-ios',
        });
      }

      if (isNative) {
        InsiderTracking.insiderIdentifiers(data.user);
      }

      return userDetail;
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const facebookLogin = (payload) => {
  return async (dispatch) => {
    try {
      const res = await User.facebookLoginRequest(payload);
      const data = res.data.data;
      //   const mobileTokenLilla = {
      //     android: null,
      //     ios: null,
      //   };

      //   if (data?.mobile_token_lilla?.length) {
      //     data.mobile_token_lilla.map((mobile) => {
      //       if (mobile.device === 'ios') {
      //         mobileTokenLilla.ios = mobile;
      //       }
      //       if (mobile.device === 'android') {
      //         mobileTokenLilla.android = mobile;
      //       }
      //     });
      //   }
      data.user.isCompleteProfile = isCompleteUserInfo({
        user: data?.user || {},
      });
      dispatch(
        authenticate({
          token: data.token,
          userId: data.user.id,
          user: { ...data.user, acquisition_source: 'facebook' },
        }),
      );
      saveDataToStorage(data.token, data.user.id, data.user);
      dispatch({
        type: 'SET_MOBILE_TOKEN_LILLA',
        data: getMobileTokenLilla(data),
      });
      const asyncData = JSON.parse(await AsyncStorage.getItem('fcmToken'));
      if (asyncData) {
        Soco.saveFirebaseToken({
          token: asyncData.fcmToken,
          device: Platform.OS === 'android' ? 'lilla-android' : 'lilla-ios',
        });
      }
      return { isCompleteProfile: isCompleteUserInfo(data) };
    } catch (err) {
      console.log(err);
      return err?.response?.data;
    }
  };
};

export const quickRegister = (payload) => {
  return async (dispatch) => {
    try {
      const res = await User.quickRegisterRequest(payload);
      if (res.status === 200) {
        const data = res.data.data;
        dispatch(authenticate({ user: data, userId: data._id }));
        saveDataToStorage(data.token, data.user.id, data.user);
        GoogleTagManager.userTrack(data.user);
        return true;
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const userSignup = (payload) => {
  return async (dispatch) => {
    try {
      let res = null;
      if (payload.hash) {
        res = await User.quickRegisterRequest(payload);
      } else if (payload?.is_qr_scan || isNative) {
        res = await User.signupRequest(payload);
      } else {
        res = await User.signupRequestWeb(payload);
        if (res.data?.data?.user) {
          GoogleTagManager.userTrack(res.data.data.user);
        }
        try {
          window.dataLayer.push({
            event: 'completeRegistration',
            register_data: {
              First_Name: payload.first_name,
              Last_Name: payload.last_name,
              Gender: payload.gender,
              User_Name: payload.user_name,
              Email_Id: payload.email,
              Mobile_No: payload.phone_no,
              Acquisition_Source: res.data.data.user.acquisition_source,
              User_Mongo_Id: res.data.data.user.id,
              Country: 'Indonesia',
            },
          });
        } catch (err) {
          console.log(err);
        }
      }

      if (res?.status == 200) {
        const data = res.data.data;
        const mobileTokenLilla = {
          android: null,
          ios: null,
        };

        if (data?.mobile_token_lilla?.length) {
          data.mobile_token_lilla.map((mobile) => {
            if (mobile.device === 'ios') {
              mobileTokenLilla.ios = mobile;
            }
            if (mobile.device === 'android') {
              mobileTokenLilla.android = mobile;
            }
          });
        }
        data.user.isCompleteProfile = isCompleteUserInfo({ user: data });
        dispatch(
          authenticate({
            token: data.token,
            userId: data.user.id,
            user: data.user,
          }),
        );
        if (!isNative) {
          window.dataLayer.push({
            event: 'Registration',
            register: {
              firstName: payload.first_name,
              lastName: payload.last_name,
              gender: payload.gender,
              userName: payload.user_name,
              email: payload.email,
              phoneNo: payload.phone_no,
              country: 'Indonesia',
            },
          });
        }

        if (isNative) {
          InsiderTracking.insiderSignupEvent();
        }
        saveDataToStorage(data.token, data.user.id, data.user);
        dispatch({ type: 'SET_MOBILE_TOKEN_LILLA', data: mobileTokenLilla });
        return data;
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const userLogout = () => {
  return async (dispatch) => {
    try {
      const asyncData = JSON.parse(await AsyncStorage.getItem('fcmToken'));
      await User.logoutRequest(asyncData.fcmToken);
      dispatch(setFreshLogin(false));
      clearStorageData('userData');
      dispatch(
        authenticate({ token: null, userId: '', user: {}, profile: {} }),
      );
      dispatch({ type: 'SET_MOBILE_TOKEN_LILLA', data: null });
      dispatch({ type: SET_PROFILE, data: {} });

      if (isNative) {
        InsiderTracking.insiderLogout();
      }

      return true;
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const userLogoutWeb = () => {
  return async (dispatch) => {
    try {
      const asyncData = JSON.parse(await AsyncStorage.getItem('fcmToken'));
      if (isNative) {
        await User.logoutRequest(asyncData.fcmToken);
      } else {
        await User.logoutRequestWeb();
        endSession();
        window.dataLayer.push({
          event: 'Logout',
        });
      }

      if (!global.isNative) {
        window.dataLayer.push({
          event: 'loggedOut',
        });
      }
      dispatch(setFreshLogin(false));
      clearStorageData('userData');
      clearStorageData('@storage_levels');
      dispatch(
        authenticate({ token: null, userId: '', user: {}, profile: {} }),
      );
      dispatch({ type: 'SET_MOBILE_TOKEN_LILLA', data: null });
      dispatch({ type: SET_PROFILE, data: {} });

      if (typeof window !== 'undefined' && window.insider_object) {
        if (window.insider_object.user) {
          delete window.insider_object;
        }

        if (window.insider_object.basket) {
          delete window.insider_object.basket;
        }
      }

      return true;
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const userPhoneCheck = (payload) => {
  return async () => {
    try {
      const res = await User.phoneCheckRequest(payload);
      if (res.status !== 200) {
        Toast.show({
          text1: 'Nomor handphone tidak terdaftar',
          topOffset: 50,
          type: 'error',
        });
        return false;
      }
      return res.data;
    } catch (err) {
      if (err?.response?.status === 422) {
        Toast.hide({});
      }
      return err?.response?.data;
    }
  };
};

export const userEmailCheck = (payload, isForgotPassword = false) => {
  return async (dispatch) => {
    try {
      const res = await User.emailCheckRequest(payload);
      if (res.status == 200) {
        if (res.data.data) {
          if (isForgotPassword) {
            return await dispatch(userForgetPassword(payload));
          } else {
            return res.data.data;
          }
        } else {
          return res.data.data;
        }
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const userNameSuggestions = (payload) => {
  return async () => {
    try {
      const res = await User.userNameSuggestionsRequest(payload);
      if (res.status == 200) {
        if (res.data.data) {
          return res.data.data;
        }
      }
    } catch (err) {
      console.log(err);
      return err?.response?.data;
    }
  };
};

export const updateUsername = (payload) => {
  return async () => {
    try {
      const res = await User.updateUsernameRequest(payload);
      if (res.status == 200) {
        if (res?.data?.data) {
          setUserData({
            user_name: res.data.data.changed_data,
            is_user_name_changed: true,
          });
          return res.data;
        }
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const userForgetPassword = (payload) => {
  return async (dispatch) => {
    try {
      const res = await User.forgetPasswordRequest(payload);
      if (res.status == 200) {
        dispatch({
          type: SET_FORGOT_PASSWORD,
          data: { user_id: res.data.data.user_id },
        });
        return res.data.success;
      }
    } catch (err) {
      console.log(err);
      return err?.response?.data;
    }
  };
};

export const changePassword = (payload) => {
  return async () => {
    try {
      const res = await User.changePasswordRequest(payload);
      if (res.status == 200) {
        return res.data.success;
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const resetPassword = (payload) => {
  return async () => {
    try {
      const res = await User.resetPasswordRequest(payload);
      if (res.status == 200) {
        return res.data.data;
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const verifyResetPassword = (payload) => {
  return async () => {
    try {
      const res = await User.verifyResetPasswordRequest(payload);
      if (res.status == 200) {
        return res.data.data;
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const getUserDetail = () => {
  return async (dispatch) => {
    try {
      dispatch(authenticateStatus('pending'));
      const res = await User.userDetailRequest();

      if (res.status == 200) {
        const data = res.data.data || {};
        GoogleTagManager.userTrack(data);
        data.isLoggedIn = Object.keys(data).length ? true : false;
        data.isCompleteProfile = isCompleteUserInfo({ user: data || {} });
        dispatch({
          type: 'SET_MOBILE_TOKEN_LILLA',
          data: getMobileTokenLilla(data),
        });
        dispatch(
          authenticate({
            userId: data?._id || null,
            token: data?.clients?.dashboard?.token || null,
            user: data,
          }),
        );
        dispatch(authenticateStatus('finished'));

        return data;
      }
    } catch (err) {
      console.log(err);
      // return err?.response?.data
    }
  };
};

export const userPreferences = (payload) => {
  return async () => {
    try {
      const res = await User.preferencesRequest(payload);
      if (res.status == 200) {
        return res.data.success;
      }
    } catch (err) {
      console.log(err);
      return err?.response?.data;
    }
  };
};

export const getUser = (params) => {
  return async () => {
    try {
      const res = await User.userRequest(params);
      if (res.status == 200) {
        return res.data.data;
      }
    } catch (err) {
      console.log(err);
      return err?.response?.data;
    }
  };
};

export const getOtp = (payload) => {
  return async () => {
    try {
      const res = await User.otpRequest(payload);
      if (res.status == 200) {
        return res.data.data;
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const getOtpLoginRequest = (payload) => {
  return async () => {
    try {
      const res = await User.loginOtpRequest(payload);
      if (res.status == 200) {
        return res.data;
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const getOtpLogin = (payload) => {
  return async () => {
    try {
      const res = await User.loginOtp(payload);
      if (res.status == 200) {
        return res.data;
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const otpVerify = (payload) => {
  return async () => {
    try {
      const res = await User.otpVerifyRequest(payload);
      if (res.status == 200) {
        if (res?.data?.data?.status === 'VERIFIED') {
          const verifiedEntity =
            payload.entity == 'email'
              ? { is_verified_email: true }
              : { is_verified_phone: true };
          setUserData(verifiedEntity);
        }
        return res.data.data;
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const getFeatureToggleOtp = (type) => {
  return async () => {
    const mode = {
      whatsapp: 'otp_whatsapp',
      sms: 'otp_sms',
      email: 'otp_email',
    };
    try {
      const res = await User.featureToggleOtpRequest(mode[type]);
      if (res.status == 200) {
        return res.data.data;
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const getProfile = (params) => {
  return async (dispatch) => {
    try {
      const res = await User.profileRequest(params);
      if (res.status == 200) {
        const result = res.data.data[0];

        result.children = result.children ?? [];
        result.recentMotherPhase = result?.children?.length
          ? result.children[0]
          : {};
        result.phase_of_mother = result.current_mother_status;
        result.childrenList = (result?.children ?? []).filter((data) => {
          if (data.phase_of_mother !== 'expecting' && data.date_of_birth) {
            const { age, fullAge } = getChildData(data.date_of_birth);
            data.dateString = formatDate(Date.parse(data.date_of_birth));
            data.fullAge = fullAge;
            data.age = age;
            return data;
          }
        });
        if (result.recentMotherPhase.phase_of_mother == 'expecting') {
          result.recentMotherPhase.pregnancy_time_period = result
            .recentMotherPhase.prediction.birth_date
            ? getPregnancyTimePeriod(
                result.recentMotherPhase.prediction.birth_date,
              )
            : result.recentMotherPhase.pregnancy_time_period;
        }
        dispatch({ type: SET_PROFILE, data: result });
        return result;
      }
    } catch (err) {
      console.log('Error: ' + err + ' Response : ' + err?.response?.data);
    }
  };
};

export const updateUser = (id, payload) => {
  return async (dispatch) => {
    try {
      const res = await User.updateUserRequest(id, payload);
      if (res.status == 200) {
        const params = {
          filter: { user_id: id, is_detail: true },
        };
        dispatch(authenticate({ user: res.data.data, isLoggedIn: true }));
        GoogleTagManager.userTrack(res.data.data);
        dispatch(getProfile(params));
        return res.data.data;
      }
    } catch (err) {
      console.log(err?.response?.data);
    }
  };
};

export const getInterestTags = (params) => {
  return async () => {
    try {
      const res = await User.tagsRequest(params);
      if (res.status == 200) {
        return res.data.data;
      }
    } catch (err) {
      console.log(err?.response?.data);
    }
  };
};

export const updateInterestTags = (payload) => {
  return async () => {
    try {
      const res = await User.saveTagsRequest(payload);
      if (res.status == 200) {
        return res;
      }
    } catch (err) {
      console.log(err?.response?.data);
    }
  };
};

export const requestToDelete = (userId) => {
  return async (dispatch) => {
    try {
      const asyncData = JSON.parse(await AsyncStorage.getItem('fcmToken'));
      await User.logoutRequest(asyncData.fcmToken);
      await Soco.requestToDeleteAccount(userId);
      dispatch(setFreshLogin(false));
      dispatch(
        authenticate({ token: null, userId: '', user: {}, profile: {} }),
      );
      clearStorageData('userData');
      dispatch({ type: 'SET_MOBILE_TOKEN_LILLA', data: null });
      dispatch({ type: SET_PROFILE, data: {} });
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const updateLegalStatement = () => {
  return async () => {
    try {
      const res = await Soco.confirmLegalStatement();
      if (res.status == 200) {
        return res.data;
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};

export const otpVerifyParent = (payload) => {
  return async () => {
    try {
      const res = await User.verifyOTPParentRequest(payload);
      if (res.status == 200) {
        return res.data.data;
      }
    } catch (err) {
      return err?.response?.data;
    }
  };
};
